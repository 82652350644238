import './assets/css/style.css';

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import { useUserStore } from './stores/userStore';
import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";

const SENTRY_DSN = process.env.VUE_APP_SENTRY_DSN;

const app = createApp(App);

Sentry.init({
    app,
    dsn: SENTRY_DSN,
    integrations: [
        new VueIntegration({ app, attachProps: true, logErrors: true })
    ],
    tracesSampleRate: 1.0,
});

const pinia = createPinia();

app.use(pinia);
app.use(router);

const userStore = useUserStore(pinia);
if (!userStore.accessToken) {
    const urlParams = new URLSearchParams(window.location.search);
    const programId = urlParams.get('program_id');
    if (programId) {
        localStorage.setItem('pendingProgramId', programId);
        router.push({ path: '/login' });
    }
}

app.mount('#app');
