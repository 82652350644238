<template>
  <div class="login-block">
    <img src="@/assets/images/LogoVzlet.svg" class="lb-logo" alt="Logo">
    <div class="welcome-text">Выполнить вход используя учетную запись:</div>
    <div class="login-buttons">
      <button @click="loginWithMesh">
        <img src="@/assets/images/mesh.png" alt="Войти через МЭШ" />
      </button>
      <button @click="loginWithSP">
        <img src="@/assets/images/shp.png" alt="Войти через ШП" />
      </button>
      <div></div>
      <div>* Для колледжей и техникумов</div>
    </div>
    <div v-if="hasLoggedOut" class="logout">
      Вы вышли из системы регистрации на программы ОЦ «Взлёт».
      Если вы также хотите выполнить выход из «Моя школа» -
      перейдите на сайт <a href="https://myschool.mosreg.ru">myschool.mosreg.ru</a> и выполните <a
        href="https://myschool.mosreg.ru">выход из системы</a>.
    </div>
    <div class="technical">
      О технических проблемах пишите на&nbsp;почту <a href="mailto:vzlet@primakov.school">vzlet@primakov.school</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginPage',
  data() {
    const isLoggedOut = this.$route.query.logout === '1';
    // console.log('Logout query param:', this.$route.query.logout, 'Is logged out:', isLoggedOut);
    return {
      hasLoggedOut: isLoggedOut
    };
  },
  methods: {
    loginWithMesh() {
      const clientId = "reg.olympmo";
      const redirectUri = encodeURIComponent(process.env.VUE_APP_REDIRECT_URI);
      const scope = encodeURIComponent('profile oic_name oic_given_name oic_family_name oic_middle_name oic_gender oic_birthdate oic_education');
      const responseType = 'code';
      const state = Math.random().toString(36).substring(7);
      const authUrl = `https://authedu.mosreg.ru/mo?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=${responseType}&state=${state}`;

      window.location.href = authUrl;
    },
    loginWithSP() {
      const clientId = "969d461db6c94c439905a78b2bb69aa0";
      const redirectUri = encodeURIComponent("https://app.olympmo.ru/auth/sp");
      const scope = "CommonInfo,EducationalInfo";
      const responseType = 'code';
      const state = Math.random().toString(36).substring(7);
      const authUrl = `https://login.school.mosreg.ru/login/?ReturnUrl=https%3a%2f%2flogin.school.mosreg.ru%2foauth2%3fclient_id%3d${clientId}%26redirect_uri%3d${redirectUri}%26scope%3d${scope}%26response_type%3d${responseType}%26state%3d${state}`;
      window.location.href = authUrl;
    },
  },
  mounted() {
    document.body.classList.add('login-body');
  },
  beforeUnmount() {
    document.body.classList.remove('login-body');
  }
};
</script>

<style scoped>

.login-block .login-buttons button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 34px;
  background-color: var(--white);
  border-radius: 4px;
  box-shadow: var(--shadow);
  transition: all 0.3s ease;
  border: none;
  cursor: pointer;
}

.login-block .logout {
  display: block;
}

</style>
